import React, { useEffect, useState } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import { withSuspense } from '../../components';
import Error404 from '../../views/Error404';
import Sidebar from './Sidebar';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_ODA } from '../../constants';
import getUserIdLogin from '../../utils/getUserIdLogin';
import LayoutHeader from './LayoutHeader';

const ViewerPage = withSuspense(React.lazy(() => import('../../viewer/ViewerPage')));
const AutodeskViewerPage = withSuspense(
  React.lazy(() => import('../../viewer/AutodeskViewerPage'))
);
const Files = withSuspense(React.lazy(() => import('../../views/Files')));
const Feedbacks = withSuspense(React.lazy(() => import('../../views/Feedbacks')));
const FeedbackDetail = withSuspense(
  React.lazy(() => import('../../views/Feedbacks/FeedbackDetail'))
);
const Tasks = withSuspense(React.lazy(() => import('../../views/Tasks')));
const TasksDetail = withSuspense(React.lazy(() => import('../../views/Tasks/TaskDetail')));
const Licenses = withSuspense(React.lazy(() => import('../../views/Licenses')));
const LicenseDetail = withSuspense(React.lazy(() => import('../../views/Licenses/LicenseDetail')));
const Profile = withSuspense(React.lazy(() => import('../../views/Profile')));
const Projects = withSuspense(React.lazy(() => import('../../views/Projects')));
const ProjectDashboard = withSuspense(React.lazy(() => import('../../views/ProjectDashboard')));
const ProjectSettings = withSuspense(React.lazy(() => import('../../views/ProjectSettings')));
const ProjectMembers = withSuspense(React.lazy(() => import('../../views/ProjectMembers')));
const ProjectModels = withSuspense(React.lazy(() => import('../../views/ProjectModels')));

const { Content } = Layout;

function SidebarLayout() {
  return (
    <div>
      <LayoutHeader />
      <Layout style={{ minHeight: 'calc(100vh - 50px)' }} className="bg-white">
        <Sidebar theme="light" active="Files"></Sidebar>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </div>
  );
}

let path = window.location.pathname;
if (path === '/') {
  path = '/files';
}

function DefaultLayout() {
  const [data, setData] = useState({});

  useEffect(() => {
    const userId = getUserIdLogin();
    setData({
      access: Cookies.get(`${ACCESS_TOKEN_KEY}_${userId}`),
      refresh: Cookies.get(`${REFRESH_TOKEN_KEY}_${userId}`),
      oda: JSON.parse(localStorage.getItem(`${USER_ODA}_${userId}`)),
      userId,
    });
  }, []);
  return (
    <Routes>
      <Route element={<SidebarLayout />}>
        <Route index element={<Navigate to={path} replace />} />
        <Route path="files">
          <Route index element={<Files data={data} />} />
          <Route path=":fileId" element={<ViewerPage />} />
          <Route path=":fileId/:fileName" element={<AutodeskViewerPage />} />
        </Route>
        <Route path="tasks">
          <Route index element={<Tasks />} />
          <Route path=":taskId" element={<TasksDetail />} />
        </Route>
        <Route path="feedbacks">
          <Route index element={<Feedbacks />} />
          <Route path=":feedbackId" element={<FeedbackDetail />} />
        </Route>
        <Route path="licenses">
          <Route index element={<Licenses />} />
          <Route path=":licenseId" element={<LicenseDetail />} />
        </Route>
        <Route path="profile">
          <Route index element={<Profile />} />
        </Route>
        <Route path="projects">
          <Route index element={<Projects />} />
          <Route path=":projectId">
            <Route index element={<ProjectDashboard />} />
            <Route path="models">
              <Route index element={<ProjectModels />} />
              <Route path=":fileId" element={<ViewerPage />} />
            </Route>
            <Route path="members" element={<ProjectMembers />} />
            <Route path="settings" element={<ProjectSettings />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default DefaultLayout;
