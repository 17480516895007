import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Badge } from 'antd';
import {
  FileOutlined,
  IssuesCloseOutlined,
  KeyOutlined,
  UserOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import axiosInstance from '../../plugins/axios';
import './SidebarTopMenu.css';
import { CURRENT_PAGE, USER_VINA } from '../../constants';
import getUserIdLogin from '../../utils/getUserIdLogin';

function SidebarTopMenu({ className }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showTask, setShowTask] = useState(false);
  const userId = getUserIdLogin();
  const [totalNewFeedback, setTotalNewFeedback] = useState(0);
  const [totalNewFeedbackByAssignee, setTotalNewFeedbackByAssignee] = useState(0);

  const path = location.pathname.split('/')[1].trim();

  const fetchData = async () => {
    try {
      const res = await axiosInstance.get('/portal/count-item-unread');
      if (res) {
        setTotalNewFeedback(res.data.totalNewFeedback);
        setTotalNewFeedbackByAssignee(res.data.totalNewFeedbackByAssignee);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(`${USER_VINA}_${userId}`) || '{}');
    if (user?.rolesOther === 'system_manage') {
      setShowTask(true);
    }

    if (!window.location.hash.includes('files')) {
      sessionStorage.removeItem(CURRENT_PAGE);
    }
    setTimeout(() => fetchData(), 1000);
  }, [location.pathname]);

  const items = [
    { key: '/files', label: t('My Files'), icon: <FileOutlined /> },
    ...(showTask
      ? [
          {
            key: '/tasks',
            label: t('My Tasks'),
            icon: <ContainerOutlined />,
            badge:
              totalNewFeedbackByAssignee === 0
                ? ''
                : totalNewFeedbackByAssignee > 0 && totalNewFeedbackByAssignee < 99
                ? totalNewFeedbackByAssignee
                : '99+',
          },
        ]
      : []),
    {
      key: '/feedbacks',
      label: t('My Feedbacks'),
      icon: <IssuesCloseOutlined />,
      badge:
        totalNewFeedback === 0
          ? ''
          : totalNewFeedback > 0 && totalNewFeedback < 99
          ? totalNewFeedback
          : '99+',
    },
    {
      key: '/licenses',
      label: t('My Licenses'),
      icon: <KeyOutlined />,
    }, //
    {
      key: '/profile',
      label: t('My Profile'),
      icon: <UserOutlined />,
    },
    // { key: '/projects', label: t('Projects'), icon: <ProjectOutlined /> },
  ];

  const menuItems = items.map((item) => ({
    key: item.key,
    icon: item.icon,
    label: (
      <>
        {item.label}
        {(item.key === '/feedbacks' || item.key === '/tasks') && item.badge && (
          <Badge className="new-badge" count={item.badge} />
        )}
      </>
    ),
    onClick: () => navigate(item.key),
  }));

  return (
    <Menu
      className={className}
      selectedKeys={[`/${path}`]}
      mode="inline"
      theme="dark"
      items={menuItems}
    />
  );
}

export default SidebarTopMenu;
