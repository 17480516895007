import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import JA from '../assets/images/ja.png';
import EN from '../assets/images/en.png';
import VI from '../assets/images/vi.png';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const locales = [
    { code: 'vi', name: 'Việt Nam' },
    { code: 'en', name: 'English' },
    { code: 'ja', name: '日本語' },
  ];

  const handleLanguageChange = (code) => {
    i18n.changeLanguage(code);
  };

  const menu = (
    <Menu>
      {locales.map((item) => (
        <Menu.Item key={item.code} onClick={() => handleLanguageChange(item.code)}>
          <div className="d-flex align-items-center">
            <img
              src={item.code === 'vi' ? VI : item.code === 'en' ? EN : JA}
              alt={item.name}
              className="language-img mr-2"
              width="50"
              height="30"
            />
            <p className="language-title mb-0">{item.name}</p>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <div className="language-dropdown">
          <img
            src={i18n.language === 'vi' ? VI : i18n.language === 'en' ? EN : JA}
            alt="logo"
            className="language-img mr-2"
            width="50"
            height="30"
          />
          <DownOutlined />
        </div>
      </Dropdown>
    </div>
  );
};

export default LanguageSwitcher;
