const ACCESS_TOKEN_KEY = 'actp';
const REFRESH_TOKEN_KEY = 'refp';
const ERROR_CODE_UNPROCESSABLE_ENTITY = 422;
const ERROR_CODE_UNAUTHORIZED = 401;
const ERROR_CODE_BAD_REQUEST = 400;
const USER_VINA = 'user_vina';
const USER_ODA = 'user';
const FREE_STORAGE_LIMIT = 2147483648; //2GB
const FILE_UPLOAD_LIMIT = 209715200; //200MB
const AUTH_USERS = 'auth_users';
const AUTODESK_VIEWER = 'autodesk_viewer';
const ACCEPT_FILE_DEFAULT = ['.DGN', '.DWF', '.DWG', '.DXF'];
const CURRENT_PAGE = 'current_page';
const VINA_CAD = 'VinaCAD';
const VINA_CAD_WEB = 'VinaCAD Web';
const USER_ID = 'userId';

const TRIAL = 'TRIAL';
const ACTIVE = 'ACTIVE';
const MONTHS_3 = 'MONTHS_3';
const MONTHS_6 = 'MONTHS_6';
const YEAR_1 = 'YEAR_1';
const YEARS_2 = 'YEARS_2';
const accountColorList = [
  '#ff69b4', // Hot pink
  '#ffd700', // Gold
  '#26c28c', // Sea green
  '#1abc9c', // Turquoise
  '#9b59b6', // Amethyst
  '#e74c3c', // Crimson
  '#3498db', // Bright blue
  '#f1c40f', // Sunny yellow
  '#7f8c8d', // Taupe
  '#dc3545', // Red
];

export {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  ERROR_CODE_UNPROCESSABLE_ENTITY,
  ERROR_CODE_UNAUTHORIZED,
  ERROR_CODE_BAD_REQUEST,
  USER_VINA,
  USER_ODA,
  ACCEPT_FILE_DEFAULT,
  FREE_STORAGE_LIMIT,
  TRIAL,
  ACTIVE,
  YEAR_1,
  YEARS_2,
  MONTHS_3,
  MONTHS_6,
  AUTH_USERS,
  AUTODESK_VIEWER,
  accountColorList,
  FILE_UPLOAD_LIMIT,
  CURRENT_PAGE,
  VINA_CAD,
  VINA_CAD_WEB,
  USER_ID,
};
