///////////////////////////////////////////////////////////////////////////////
// Copyright (C) 2002-2021, Open Design Alliance (the "Alliance").
// All rights reserved.
//
// This software and its documentation and related materials are owned by
// the Alliance. The software may only be incorporated into application
// programs owned by members of the Alliance, subject to a signed
// Membership Agreement and Supplemental Software License Agreement with the
// Alliance. The structure and organization of this software are the valuable
// trade secrets of the Alliance and its suppliers. The software is also
// protected by copyright law and international treaty provisions. Application
// programs incorporating this software must include the following statement
// with their copyright notices:
//
//   This application incorporates Open Design Alliance software pursuant to a
//   license agreement with Open Design Alliance.
//   Open Design Alliance Copyright (C) 2002-2021 by Open Design Alliance.
//   All rights reserved.
//
// By use of this software, its documentation or related materials, you
// acknowledge and accept the above terms.
///////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import { ErrorBoundary, ErrorStub, Loading, PrivateRoute } from './components';
import DefaultLayout from './layout';
import { AppContext } from './AppContext';
import './App.css';
import { generateLocaleForAntD } from './Localization';
import { useTranslation, withTranslation } from 'react-i18next';

function App() {
  const [loading, setLoading] = useState(true);
  const { app } = useContext(AppContext);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    app
      .loginFromStorage()
      .catch((e) => console.log('Cannot login from storage.', e.message))
      .finally(() => {
        const isReturnApp = urlParams.get('isReturnApp');
        sessionStorage.setItem('isReturnApp', isReturnApp);
        navigate(`${window.location.pathname}`, {
          replace: true,
        }); // Thay thế URL hiện tại
        setLoading(false);
      });

    const lang = urlParams.get('lang');
    i18n.changeLanguage(lang);
  }, [app]);

  return (
    <ConfigProvider locale={generateLocaleForAntD()}>
      <ErrorBoundary fallback={ErrorStub}>
        <Loading loading={loading}>
          <Routes>
            <Route
              path="*"
              element={<PrivateRoute user={app.user} element={<DefaultLayout />} tag={'012'} />}
            />
          </Routes>
        </Loading>
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default withTranslation()(App);
